import React from 'react'

import ProductHeader from '../templates/ProductPage/ProductHeader'
import SEO from '../components/seo'

const PolitiqueDeRemboursement = () => {
  return (
    <>
      <SEO title="Politique de remboursement" description="Page Politique de remboursement" />
      <ProductHeader title='Politique de remboursement' />

      <div className='page-legale'>

        <p><i>applicable à compter du <span className='mention' data-mention='{{runtime:currentDate}}'>22/09/2021</span></i>
        </p>
        <h3><strong>ARTICLE 1. PARTIES</strong></h3>
        <p>La présente politique de remboursement est applicable entre <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                                       >JH &amp; Co
        </span>, <span
                                                                         className='mention' data-mention='{{company:companyType}}'
                                                                                >SARL
                                                                                </span>, capital social : <span
                   className='mention' data-mention='{{company:capital}}'
                                           >1000
                                           </span> €, enregistrée au&nbsp;RCS de <span
                                                                                                            className='mention' data-mention='{{company:registrationState}}'
                                                                                                                                                >Creteil
                                                                                                                                                </span> en <span
                                                                                   className='mention'
                                                                                   data-mention='{{company:registrationCountry}}'
                                                                                            >France
                                                                                            </span> le <span
                                                                                                                                                             className='mention' data-mention='{{company:registrationDate}}'
                                                                                                                                                                      >28/11/2016
                                                                                                                                                                      </span>, sous le numéro <span
                                                                                                         className='mention' data-mention='{{company:companyRegistrationID}}'
                                                                                                                               >82391901400014
                                                                                                                               </span>, siège social
          : <span
              className='mention'
              data-mention='{{company:fullAddress}}'
            >1 bis rue blanqui, 94200 Ivry sur seine, FR
          </span>, téléphone
          : <span className='mention' data-mention='{{company:phone}}'>+33620142775</span>, email : <span
              className='mention' data-mention='{{company:email}}'
                                                                                                    >jhandco.dev@gmail.com
          </span>, TVA non applicable,
          article 293B du CGI, ci-après «&nbsp;l’Editeur&nbsp;» et toute personne, physique ou morale, de droit privé ou
          de droit public, inscrite sur le Site pour acheter un Produit, ci-après «&nbsp;le Client&nbsp;».
        </p>
        <p>&nbsp;</p>
        <h3><strong>ARTICLE 2. DEFINITIONS</strong></h3>
        <p>«&nbsp;<strong>Client&nbsp;</strong>» : toute personne, physique ou morale, de droit privé ou de droit
          public, inscrite sur le Site.
        </p>
        <p>«&nbsp;<strong>Contenus du Site&nbsp;»</strong> : éléments de toute nature publiés sur le Site, protégés ou
          non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas,
          structures, bases de données ou logiciels.&nbsp;
        </p>
        <p>«&nbsp;<strong>L’Editeur</strong>&nbsp;» : <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                      >JH &amp; Co
        </span>, <span
                                                        className='mention' data-mention='{{company:companyType}}'
                                                               >SARL
                                                               </span> pris en sa qualité d’éditeur du Site.
        </p>
        <p>«&nbsp;<strong>Internaute</strong>&nbsp;» : toute personne, physique ou morale, de droit privé ou de droit
          public, se connectant au Site.
        </p>
        <p>«&nbsp;<strong>Produit</strong>&nbsp;»&nbsp;: bien de toute nature vendu sur le Site par l’Editeur aux
          Clients.
        </p>
        <p>«&nbsp;<strong>Site&nbsp;</strong>» : site internet accessible à l’URL <span
          className='mention'
          data-mention='{{company:websiteName}}'
                                                                                  >https://jhnco.fr
        </span>,
          ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférents.
        </p>
        <h3><strong>ARTICLE 3. CHAMP D’APPLICATION</strong></h3>
        <p>Le Site est d'accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l'acceptation par
          tout Internaute des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce
          soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des
          présentes conditions générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le
          fait de cocher la case correspondante.
        </p>
        <p>L'Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans
          restriction.&nbsp;
        </p>
        <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de
          l’Internaute. L'Internaute reconnaît la valeur de preuve des systèmes d'enregistrement automatique de
          L’Editeur et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.
        </p>
        <p>Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes
          autres conditions, et notamment celles de l’Internaute.&nbsp;
        </p>
        <p>L'acceptation des présentes conditions générales suppose de la part des Internautes qu'ils jouissent de la
          capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un
          curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient
          titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.
        </p>
        <h3><strong>ARTICLE 4. OBJET DU SITE</strong></h3>
        <p>Le Site a pour objet la vente de Produits aux Clients.</p>
        <h3><strong>ARTICLE 5. ETAPES DE LA COMMANDE</strong></h3>
        <h4><strong>5.1. Commande</strong></h4>
        <p>Afin de passer commande, les Internautes pourront sélectionner un ou plusieurs Produits et les ajouter à leur
          panier. La disponibilité des Produits est indiquée sur le Site, dans la fiche descriptive de chaque article.
          Lorsque leur commande sera complète, ils pourront accéder à leur panier en cliquant sur le bouton prévu à cet
          effet.
        </p>
        <h4><strong>5.2. Validation de la commande par l’Internaute</strong></h4>
        <p>En consultant leur panier, les Internautes auront la faculté de vérifier le nombre ainsi que la nature des
          Produits qu'ils auront choisis et pourront vérifier leur prix unitaire, ainsi que leur prix global. Ils auront
          la possibilité de retirer un ou plusieurs Produits de leur panier.&nbsp;
        </p>
        <p>Si leur commande leur convient, les Internautes pourront la valider. Ils accéderont alors à un formulaire sur
          lequel ils pourront soit saisir leurs identifiants de connexion s'ils en possèdent déjà, soit s'inscrire sur
          le Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.
        </p>
        <h4>5.3. <strong>Paiement par le Client</strong></h4>
        <p>Dès lors qu'ils seront connectés ou après qu'ils auront parfaitement complété le formulaire d’inscription,
          les Clients seront invités à contrôler ou modifier leurs coordonnées de livraison et de facturation, puis
          seront invités à effectuer leur paiement en étant redirigés à cet effet sur l'interface de paiement sécurisée
          comportant la mention «&nbsp;commande avec obligation de paiement&nbsp;» ou toute formule analogue.&nbsp;
        </p>
        <h4>5.4. <strong>Confirmation de la commande par L’Editeur</strong></h4>
        <p>Une fois le paiement effectivement reçu par L’Editeur, ce dernier s'engage à en accuser réception au Client
          par voie électronique, dans un délai maximal de 24 heures. Dans le même délai, L’Editeur s'engage à adresser
          au Client un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant
          toutes les informations y afférant.
        </p>
        <h3><strong>ARTICLE 6. PRIX - PAIEMENT</strong></h3>
        <h4><strong>6.1. Prix</strong></h4>
        <p>Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à
          tout moment par L’Editeur. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas
          effet pour l'avenir.
        </p>
        <p>Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises, hors frais de livraison.</p>
        <h4><strong>6.2. Modalité de paiement</strong></h4>
        <p>Le Client peut effectuer son règlement par <span
          className='mention'
          data-mention='{{company:paymentGateways}}'
                                                      >Stripe
        </span>.
        </p>
        <p>Dans le cadre des paiements par carte bancaire, L’Editeur n'a accès à aucune donnée relative aux moyens de
          paiement du Client. Le paiement est effectué directement entre les mains de l'établissement bancaire.
        </p>
        <p>En cas de paiement par mandat, chèque ou virement bancaire, les délais de livraison ne commencent à courir
          qu'à compter de la date de l’encaissement du paiement par L’Editeur.
        </p>
        <h4><strong>6.3. Facturation</strong></h4>
        <p>L’Editeur adressera ou mettra à disposition du Client une facture par voie électronique après chaque
          paiement. Le Client accepte expressément de recevoir les factures par voie électronique.
        </p>
        <h4><strong>6.4. Défaut de paiement</strong></h4>
        <p>Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas
          de litige.
        </p>
        <p>Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de
          pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette
          pénalité nuise à l’exigibilité des sommes dues en principal.
        </p>
        <p>En outre, tout retard de paiement aura pour conséquence la facturation au Client défaillant de frais de
          recouvrement d'un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restant dues quels que
          soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la
          possibilité de résilier le contrat unilatéralement au tort du Client. La présente clause s’inscrit dans le
          cadre des dispositions de l’article 1152 du code civil permettant au juge de réduire l’indemnité si le juge
          estime qu’elle est excessive.
        </p>
        <h4>6.5. <strong>Réserve de propriété</strong></h4>
        <p>Les Produits vendus restent la propriété de L’Editeur jusqu'à complet paiement de leur prix, conformément à
          la présente clause de réserve de propriété.
        </p>
        <h3><strong>ARTICLE 7. RECLAMATION - RETRACTATION – GARANTIE</strong></h3>
        <h4><strong>7.1. Service clientèle</strong></h4>
        <p>&nbsp;</p>
        <p>Le service clientèle du Site est accessible depuis la page contact du site: <span
          className='mention'
          data-mention='{{company:afterSaleWebPage}}'
                                                                                       >https://jhnco.fr/contact
        </span> ou
          par email à <span
            className='mention'
            data-mention='{{company:afterSaleEmail}}'
                      >jhandcoparis@gmail.com
          </span> ou par courrier
          postal à l’adresse indiquée à l’article 1 des présentes conditions générales.
        </p>
        <h4><strong>7.2. Droit de rétractation – Vente à distance</strong></h4>
        <p>Le présent article 7.2 est applicable au Client ayant la qualité de consommateur au sens de l’article
          liminaire du Code de la consommation.
        </p>
        <h5><strong>7.2.1. Conditions d’exercice du droit de rétractation</strong></h5>
        <p>Conformément à la législation en vigueur en matière de vente à distance, le Client dispose d'un délai de
          quatorze jours francs pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de
          pénalités, à l'exception, le cas échéant, des frais de retour.&nbsp;
        </p>
        <p>Le délai mentionné à l'alinéa précédent court à compter&nbsp;soit&nbsp;du jour où le contrat à distance est
          conclu pour les contrats relatifs à la fourniture d’une prestation de service et/ou à la fourniture d’un
          contenu numérique non fourni sur un support matériel, soit de la réception du bien par le Client ou un tiers,
          autre que le transporteur, désigné par lui, pour les contrats de vente de biens et les contrats de prestation
          de services incluant la livraison de biens.
        </p>
        <p>Dans le cas d'une commande portant sur plusieurs biens livrés séparément ou dans le cas d'une commande d'un
          bien composé de lots ou de pièces multiples dont la livraison est échelonnée sur une période définie, le délai
          court à compter de la réception du dernier bien ou lot ou de la dernière pièce. Pour les contrats prévoyant la
          livraison régulière de biens pendant une période définie, le délai court à compter de la réception du premier
          bien.
        </p>
        <p>Lorsque le délai de quatorze jours expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé
          jusqu'au premier jour ouvrable suivant.
        </p>
        <p>La décision de rétractation devra être notifiée à L’Editeur aux coordonnées indiquées à l’article 1 des
          présentes conditions générales au moyen d’une déclaration dénuée d’ambigüité. Le Client a, par exemple, la
          possibilité d’utiliser le formulaire-type fourni à la fin des présentes conditions générales. En tout état de
          cause, l’Editeur adressera au Client au plus tôt un accusé de réception de ladite rétractation par courrier
          électronique.
        </p>
        <h5><strong>7.2.2. Effets du droit de rétractation</strong></h5>
        <p>Le Client renvoie ou restitue les produits au professionnel ou à toute personne désignée par ce dernier, sans
          retard excessif et, au plus tard, dans les quatorze jours suivant la communication de sa décision de se
          rétracter.
        </p>
        <p>Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le Client de la totalité
          des sommes versées, dans les meilleurs délais et au plus tard dans les quatorze jours suivant la date à
          laquelle ce droit a été exercé. Le cas échéant, le professionnel peut différer le remboursement jusqu'à
          récupération des Produits ou jusqu'à ce que le Client ait fourni une preuve de l'expédition des Produits, au
          plus tôt des deux évènements. Au-delà, la somme due est, de plein droit, productive d'intérêts au taux légal
          en vigueur, tel que précisé à l’article L. 242-4 du Code de la consommation.&nbsp;
        </p>
        <p>Le cas échéant, le professionnel effectue le remboursement en utilisant le même moyen de paiement que celui
          utilisé par le Client pour la transaction initiale, sauf accord exprès du Client pour l’utilisation d’un autre
          moyen de paiement et dans la mesure où le remboursement n'occasionne pas de frais pour le Client. Cependant,
          le professionnel n'est pas tenu de rembourser les frais supplémentaires si le Client a expressément choisi un
          mode de livraison plus coûteux que le mode de livraison standard proposé.&nbsp;
        </p>
        <p>Les frais directs de renvoi du Produit sont à la charge du Client. Ces frais sont estimés à un maximum
          de <span className='mention' data-mention='{{return:maximumDirectCostsForReturns}}'>4</span> euros si, en
          raison de sa nature, le Produit ne peut normalement être renvoyé par la poste.
        </p>
        <p>La responsabilité du Client n’est engagée qu’à l’égard de la dépréciation du Produit résultant de
          manipulations autres que celles nécessaires pour établir la nature, les caractéristiques et le bon
          fonctionnement de ce Produit.
        </p>
        <p>Les conditions, délais et modalités d’exercice du droit de rétractation sont exposés dans le formulaire-type
          fourni à la fin des présentes conditions générales.
        </p>
        <h5><strong>7.2.3. Exclusions du droit de rétractation</strong></h5>
        <p>Le droit de rétractation ne s’applique pas, notamment, aux contrats :</p>
        <ul>
          <li>de fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l'exécution a
            commencé après accord préalable exprès du Client et renoncement exprès à son droit de rétractation ;
          </li>
          <li>de fourniture de biens confectionnés selon les spécifications du Client ou nettement personnalisés ;</li>
          <li>de fourniture de biens susceptibles de se détériorer ou de se périmer rapidement ;</li>
          <li>de fourniture de biens qui ont été descellés par le Client après la livraison et qui ne peuvent être
            renvoyés pour des raisons d'hygiène ou de protection de la santé ;
          </li>
          <li>de fourniture de biens qui, après avoir été livrés et de par leur nature, sont mélangés de manière
            indissociable avec d'autres articles ;
          </li>
          <li>de fourniture de boissons alcoolisées dont la livraison est différée au-delà de trente jours et dont la
            valeur convenue à la conclusion du contrat dépend de fluctuations sur le marché échappant au contrôle du
            professionnel ;
          </li>
          <li>de travaux d'entretien ou de réparation à réaliser en urgence au domicile du Client et expressément
            sollicités par lui, dans la limite des pièces de rechange et travaux strictement nécessaires pour répondre à
            l'urgence ;
          </li>
          <li>de fourniture d'enregistrements audio ou vidéo ou de logiciels informatiques lorsqu'ils ont été descellés
            par le Client après la livraison ;
          </li>
          <li>de fourniture d'un journal, d'un périodique ou d'un magazine, sauf pour les contrats d'abonnement à ces
            publications ;
          </li>
          <li>conclus lors d'une enchère publique ;</li>
          <li>de prestations de services d'hébergement, autres que d'hébergement résidentiel, de services de transport
            de biens, de locations de voitures, de restauration ou d'activités de loisirs qui doivent être fournis à une
            date ou à une période déterminée ;
          </li>
          <li>de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après
            accord préalable exprès du Client et renoncement exprès à son droit de rétractation.
          </li>
        </ul>
        <p>De même, le droit de rétractation n’est pas applicable aux contrats exécutés intégralement par les deux
          parties à la demande expresse du Client avant que ce dernier n'exerce son droit de rétractation.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h4><strong>7.3. Résolution du contrat sur l’initiative du Client</strong></h4>
        <p>Le Client consommateur peut dénoncer le contrat par lettre recommandée avec demande d'avis de réception en
          cas de dépassement de la date de livraison du bien excédant sept jours. Le Client sera alors remboursé des
          sommes engagées par lui lors de la commande.
        </p>
        <p>La présente clause n'a pas vocation à s'appliquer si le retard de livraison est dû à un cas de force majeure.
          En pareil cas, le Client s'engage à ne pas exercer de poursuites à l'encontre de L’Editeur et renonce à se
          prévaloir de la résolution de la vente prévue au présent article.
        </p>
        <h4><strong>7.4. Garanties</strong></h4>
        <h5><strong>7.4.1. Garantie des vices et défauts apparents</strong></h5>
        <p>Il appartient au Client de vérifier le bon état des Produits au moment de la livraison. Cette vérification
          doit notamment porter sur la qualité, les quantités et les références des Produits ainsi que leur conformité à
          la commande. Aucune réclamation ne sera prise en compte après un délai de trois jours à compter de la
          livraison. En tout état de cause, toute réclamation concernant les colis livrés ne sera prise en compte que si
          le Client ayant la qualité de commerçant a émis des réserves auprès du transporteur conformément aux articles
          L. 133-3 et suivants du Code de commerce
        </p>
        <h5><strong>7.4.2. Garantie des vices et défauts cachés</strong></h5>
        <h6><i>7.4.2.1. Garanties légales</i></h6>
        <p>Les Clients disposent d'une garantie légale de délivrance conforme (article 1604 du Code civil), d’une
          garantie légale contre les vices cachés (articles 1641 et&nbsp;s. du Code civil) et d’une garantie de sécurité
          (articles 1245 et s. du Code civil).&nbsp;
        </p>
        <p>Les Clients ayant la qualité de consommateurs disposent en outre d'une garantie légale de conformité
          (articles L. 217-4 et s. Code de la consommation).&nbsp;
        </p>
        <h6><i>7.4.2.2. Garantie conventionnelle</i></h6>
        <p>Les Produits bénéficient, outre la garantie légale, d'une garantie conventionnelle de conformité sur le
          territoire français, d'une durée de <span
            className='mention'
            data-mention='{{return:durationOfConventionalWarranty}}'
                                              >1
          </span> ans
          à compter de la délivrance du Produit.
        </p>
        <h6><i>7.4.2.3. Retour</i></h6>
        <p>Afin de mettre en œuvre la garantie, il appartient au Client de retourner le produit à l'adresse du siège de
          L’Editeur, accompagné d'une lettre explicative en demandant soit la réparation, soit l’échange, soit le
          remboursement.&nbsp;
        </p>
        <p>En tout état de cause, il est demandé au Client de suivre précisément les instructions de l’Editeur relatives
          au retour des Produits.
        </p>
        <p>Les frais de retour du Produit demeurent à la charge du Client, sauf pour les Clients consommateurs mettant
          en œuvre la garantie de conformité des articles L. 217-4 et s. du Code de la consommation.
        </p>
        <figure className='table'>
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Le Client consommateur&nbsp;bénéficie d’un délai de 2 ans à compter de la délivrance du bien pour
                    agir auprès du vendeur. A ce titre, il peut choisir entre la réparation ou le remplacement du Produit,
                    sous réserve des conditions de coût prévues par l’article L.217-9 du Code de la consommation. Enfin,
                    le Client est dispensé de rapporter la preuve de l’existence du défaut de conformité du Produit durant
                    les 24 mois suivant la délivrance dudit Produit, sauf pour les biens d’occasion.
                  </p>
                  <p>&nbsp;</p>
                  <p>Le cas échéant, la garantie légale de conformité s’applique indépendamment de la garantie
                    commerciale.&nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <p>Lorsque le Client consommateur décide de mettre en œuvre la garantie des vices cachés, il&nbsp; peut
                    choisir entre la résolution de la vente ou une réduction du prix de vente.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </figure>
        <h3><strong>ARTICLE 8. STIPULATIONS FINALES</strong></h3>
        <h4><strong>8.1. Droit applicable</strong></h4>
        <p>Les présentes conditions générales sont soumises à l'application du droit français.</p>
        <h4><strong>8.2. Modifications des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales peuvent être modifiées à tout moment par L’Editeur. Les conditions
          générales applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le
          présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles
          conditions générales.
        </p>
        <h4><strong>8.3. Litiges</strong></h4>
        <p>En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges avec un Client consommateur qui pourraient
          survenir dans le cadre de l'exécution des présentes conditions générales et dont la solution n’aura pu être
          trouvée préalablement à l’amiable entre les parties devra être soumis.
        </p>
        <p>En outre, le Client consommateur est informé de l’existence de la plateforme de règlement en ligne des
          litiges, accessibles à l’adresse URL suivante&nbsp;: <a
            href='https://ec.europa.eu/consumers/odr/main/?event=main.home2.show'
            target='_blank'
                                                               >https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
          </a>.
        </p>
        <p>Depuis le 1er janvier 2016, la médiation est obligatoire pour tous. Ainsi, tout professionnel vendant à des
          particuliers, est tenu de communiquer les coordonnées d'un Médiateur compétent en cas de litige, et ce peu
          importe qu'il vende à distance ou dans un magasin physique (Source: FEVAD).
        </p>
        <p><span className='mention' data-mention='{{company:mediationServiceName}}'>Contact</span> &nbsp;/&nbsp; <span
          className='mention' data-mention='{{company:mediationUrl}}'
                                                                                                                  >https://jhnco.fr/contact
        </span>
        </p>
        <h4><strong>8.4. Entièreté</strong></h4>
        <p>La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou
          du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties
          devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant
          à l'esprit et à l'objet des présentes.
        </p>
        <h4><strong>8.5. Non-renonciation</strong></h4>
        <p>L'absence d'exercice par L’Editeur des droits qui lui sont reconnus par les présentes ne pourra en aucun cas
          être interprétée comme une renonciation à faire valoir lesdits droits.
        </p>
        <h4><strong>8.6. Démarchage téléphonique</strong></h4>
        <p>Le Client est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage
          téléphonique à l’adresse <a
            href='http://www.bloctel.gouv.fr/'
            target='_blank'
                                   >http://www.bloctel.gouv.fr/
          </a>.&nbsp;
        </p>
        <h4><strong>8.7. Langues des présentes conditions générales</strong></h4>
        <p>Les présentes conditions générales sont proposées en français.</p>
        <h4><strong>8.8. Clauses abusives</strong></h4>
        <p>Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions
          impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un
          professionnel et un consommateur.
        </p>
      </div>
    </>

  )
}

export default PolitiqueDeRemboursement
